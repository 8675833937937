import {
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid
} from '@mui/material';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';

import CreateConstants from "./additions/constants.js";
import {
  signInClickHandle,
  signUpClickHandle,
  hideError,
  CustomTabPanel,
  handleChangeTab,
  showError
} from "./additions/functions.js"
import { onNewDataFromServer } from './additions/useEffect_handler_func.js';


function Login() {
  const constants = CreateConstants();

  useEffect(() => {

  }, [constants.readyState])
  
  useEffect(() => {
    onNewDataFromServer(constants, showError, Cookies);
    // eslint-disable-next-line
  }, [constants.lastJsonMessage]);

  

  return (
    <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'gray' }}>

        <Box {...constants.stateErrorMsg}>
          <Grid container>
              <Grid item xs>
                <Typography sx={{padding: 1, wordBreak: 'break-word', color: 'white'}} id="error-text">Something went wrong.</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{width: '40px', height: '100%', fontSize: 25, textAlign: 'center', color: 'white', backgroundColor: 'darkred', borderRadius: '0px 7px 7px 0px', cursor: 'pointer'}}  onClick={(_) => hideError(constants)}>x</Typography>
              </Grid>
          </Grid>
        </Box>


        <Box sx={{ width: 300, backgroundColor: constants.darkTheme? '#1A2027': 'white', top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)', borderRadius: '15px',boxShadow: '0px 0px 39px 9px rgba(0,0,0,0.41)'}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: constants.darkTheme? '#1A2027': 'white', borderRadius: '15px 15px 0px 0px'}}>
            <Tabs value={constants.loginOrRegisterTab} onChange={(_, v) => handleChangeTab(constants, v)} aria-label="sign in or sign up tab choice" variant="fullWidth">
              <Tab label="Sign In" />
              <Tab label="Sign Up" />
            </Tabs>
          </Box>

          <CustomTabPanel value={constants.loginOrRegisterTab} index={0}>
            <Typography sx={{width: '100%', textAlign: 'center', fontSize: 30}}>Sign In</Typography>
            <TextField sx={{width: '100%', marginTop: '15px'}} id="signin-emailfield" label="E-Mail" value={constants.SignInEmail} onChange={(e) => {constants.setSignInEmail(e.target.value)}} variant="outlined" {...constants.stateSignInEmailInputField}/>
            <TextField sx={{width: '100%', marginTop: '15px'}} id="signin-passwordfield" label="Password" value={constants.SignInPassword} onChange={(e) => {constants.setSignInPassword(e.target.value)}} type="password" variant="outlined" {...constants.stateSignInPasswordInputField}/>
            <Button variant="contained" sx={{width: '100%', height: 50,marginTop: '25px'}} onClick={(_) => signInClickHandle(constants)}>Sign In</Button>
          </CustomTabPanel>

          <CustomTabPanel value={constants.loginOrRegisterTab} index={1}>
            <Typography sx={{width: '100%', textAlign: 'center', fontSize: 30}}>Sign Up</Typography>
            <TextField sx={{width: '100%', marginTop: '15px'}} id="signup-emailfieid" label="E-Mail" value={constants.SignUpEmail} onChange={(e) => {constants.setSignUpEmail(e.target.value)}} variant="outlined" {...constants.stateSignUpEmailInputField}/>
            <TextField sx={{width: '100%', marginTop: '15px'}} id="signup-passwordfield" label="Password" value={constants.SignUpPassword} onChange={(e) => {constants.setSignUpPassword(e.target.value)}} type="password" variant="outlined" {...constants.stateSignUpPasswordInputField}/>
            <TextField sx={{width: '100%', marginTop: '15px'}} id="signup-fnamefield" label="First name" value={constants.SignUpFName} onChange={(e) => {constants.setSignUpFName(e.target.value)}} variant="outlined" {...constants.stateSignUpFNameInputField}/>
            <TextField sx={{width: '100%', marginTop: '15px'}} id="signup-lnamefiled" label="Last name" value={constants.SignUpLName} onChange={(e) => {constants.setSignUpLName(e.target.value)}} variant="outlined" {...constants.stateSignUpLNameInputField}/>
            <Button variant="contained" sx={{width: '100%', height: 50,marginTop: '25px'}} onClick={(_) => signUpClickHandle(constants)}>Sign Up</Button>
          </CustomTabPanel>
        </Box>
    </Box>
  );
}


export default Login;
