import * as React from 'react';
import { MessageFrameOutgoing } from './Style';
import { TextSplash } from "../../Misc/TextSplash";

export const OutgoingUnsupportedMessage = (props) => {
    const { timeText } = props;
    
    return (
        <MessageFrameOutgoing>
            <TextSplash>
                Oh no. Unsuported message type. Is client updated?
            </TextSplash>

            <p style={{textAlign:'left',color:'lightgray', margin: 0}}>
                {timeText}
            </p>
        </MessageFrameOutgoing>
    );
}