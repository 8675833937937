import * as React from 'react';
import { MessageFrameOutgoing } from './Style';
import Markdown from 'markdown-to-jsx';

export const OutgoingTextMessage = (props) => {
    const { messageText, timeText } = props;
    
    return (
        <MessageFrameOutgoing>
            <p style={{margin: 0, textAlign: "left", maxWidth: 300}}>
                <Markdown>{messageText}</Markdown>
            </p>

            <p style={{textAlign:'left',color:'lightgray', margin: 0}}>
                {timeText}
            </p>
        </MessageFrameOutgoing>
    );
}