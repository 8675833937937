import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MessageFrameIncoming = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    color: theme.palette.mode === 'dark' ? "white": "black",
    ...theme.typography.body2,
    textAlign: 'center',
    border: '0px',
    maxWidth: 300,
    minWidth: 10,
    padding: 10,
    paddingBottom: 3,
    marginRight: 'auto',
    marginLeft: 5,
    borderRadius: 15,
    borderBottomLeftRadius: 0,
    wordWrap: 'break-word',
    display: 'table',
}));