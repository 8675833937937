import * as React from 'react';
import { useTheme } from '@emotion/react';
import { TextSplash, TextSplashCentered } from '../../Components/Misc/TextSplash.jsx';

import {
    Box,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@mui/material';
import { stringToColor } from '../../Functions/functions.js';
import { ChatObject } from './ChatObject.jsx';

export const ChatsList = (props) => {
    const { searchText, chatsList, userMeInfo, openedChatID, constants, webSocketWorkers } = props;
    let ElementUsersList = [];
    const theme = useTheme();

    if (chatsList.length === 0) {
        ElementUsersList.push(
            <TextSplashCentered>Loading...</TextSplashCentered>
        );
        return ElementUsersList;
    } else {
        const chatsLength = chatsList.length
        for (let i = 0; i < chatsList.length; i++) {
            let userInfo = chatsList[i];

            let showResult = true;
            let resultID = null;
            let resultName = "Unknown User";
            let resultAvatarURL = "deleted_account.jpg";
            let resultLastMessage = "No messages";

            
            if (userInfo.chat_type === "private") {

                resultID = userInfo.user_id;

                if (userInfo.user_id === userMeInfo.user_id) {
                    resultName = "Private space"
                    resultAvatarURL = "bookmark.jpg"
                } else {
                    resultName = userInfo["first_name"]+" "+userInfo["last_name"];
                    resultAvatarURL = userInfo.avatar;
                }

                if (userInfo.last_message !== null) {
                    if (userInfo.last_message.from_user.user_id === userMeInfo.user_id) {
                        resultLastMessage = "You: "
                    } else {
                        resultLastMessage = ""
                    }

                    if (userInfo.last_message.type === "text") {
                        resultLastMessage += userInfo.last_message.message
                    } else {
                        resultLastMessage += "Unable to render message"
                    }
                }
            } else if (userInfo.chat_type === "chat") {
                resultID = userInfo.chat_id

                if (userInfo.user_id === userMeInfo.user_id) {
                    resultName = "Private space"
                    resultAvatarURL = "bookmark.jpg"
                } else {
                    resultName = userInfo["name"];
                    resultAvatarURL = null;
                }

                if (userInfo.last_message !== null) {
                    if (userInfo.last_message.from_user.user_id === userMeInfo.user_id) {
                        resultLastMessage = "You: "
                    } else {
                        resultLastMessage = userInfo.last_message.from_user.first_name.substring(0, 1) + ". " + userInfo.last_message.from_user.last_name + ": "
                    }

                    if (userInfo.last_message.type === "text") {
                        resultLastMessage += userInfo.last_message.message
                    } else {
                        resultLastMessage += "Unable to render message"
                    }
                }
            }
            if (typeof(searchText) == 'string') {
                if (searchText.trim() !== "") {
                    if ((resultName.toLowerCase()).includes(searchText.toLowerCase())) {
                        showResult = true
                    } else {
                        showResult = false
                    }
                } else {
                    showResult = true
                }
            }
            
            var isSelectedChat = false
            if (typeof(openedChatID) !== typeof([])) {
                if (resultID === openedChatID) {
                    isSelectedChat = true
                }
            }

            if (showResult) {
                ElementUsersList.push(
                    <ChatObject
                        ID={resultID}
                        name={resultName}
                        avatarURL={resultAvatarURL}
                        constants={constants}
                        webSocketWorkers={webSocketWorkers}
                        isSelectedChat={isSelectedChat}
                        lastMessage={resultLastMessage}
                        position={i}
                        chatsLength={chatsLength}
                    />
                )
            }
        }
        if (typeof(searchText) == "string") {
            if (searchText.trim() !== "") {
                return [
                    <TextSplash>Global search:</TextSplash>,
                    <Box sx={{backgroundColor: '#dag', borderRadius: 10}}>
                        <ListItem
                            onClick={() => {}}
                            sx={{
                                padding: 1,
                                marginTop: 0.5,
                                borderRadius: 5,
                                backgroundColor: theme.palette.mode === "dark"?     "#404040": "#e9e9e9",
                                "&:hover":{
                                    backgroundColor: theme.palette.mode === "dark"? "#505050": "#f2f2f2"
                                },
                                "&:active":{
                                    backgroundColor: theme.palette.mode === "dark"? "#313131": "#e0e0e0"
                                }
                            }}>
                        <ListItemAvatar>
                            <Avatar alt={""}  sx={{ width: 45, height: 45, backgroundColor: stringToColor("-")}} src={null}>{"-"}</Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{userSelect: 'none', overflow: "hidden", whiteSpace: "nowrap"}} primary={"Placeholder"} secondary={"No sense, just prepared for future"}/>
                        </ListItem>
                    </Box>,
                    <TextSplash>Local search:</TextSplash>,
                    ElementUsersList
                ]
            } else {
                return ElementUsersList;
            }
        } else {
            return ElementUsersList;
        }
    }
}