import { useRef, useState } from 'react';
import useWebSocket  from "react-use-websocket";
export let deletedUser = {
    "first_name":"Deleted",
    "last_name":"User",
    "user_id": -1
}

const CreateConstants = () => {

    const messageFrameRef = useRef(null);

    const [chatHistoryRendered, setChatHistoryRendered] = useState([]);
    const [messageText, setMessageText] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [userMeInfo, setUserMeInfo] = useState([""]);
    const [openedChatID, setOpenedChatID] = useState([""]);
    const [openedChatName, setOpenedChatName] = useState([""]);
    const [openedChatAvatarURL, setOpenedChatAvatarURL] = useState([""]);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);


    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        process.env.REACT_APP_SERVER_URL,
        {
            shouldReconnect: () => true,
        }
    );

    
    return {
        sendJsonMessage, lastJsonMessage, readyState,
        messageFrameRef,
        openedChatID, setOpenedChatID,
        openedChatName, setOpenedChatName,
        openedChatAvatarURL, setOpenedChatAvatarURL,
        chatHistoryRendered, setChatHistoryRendered,
        messageText, setMessageText,
        chatHistory, setChatHistory,
        userMeInfo, setUserMeInfo,
        isDesktop, setIsDesktop,
    };
}

export default CreateConstants;