import * as React from 'react';
import { mdiAccountGroup, mdiBullhorn } from '@mdi/js';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Icon from '@mdi/react';

export const HomeContent = (props) => {
    const { setCreateScreen } = props

    return (<List sx={{width: 300}}>
    
    <ListItem disablePadding onClick={() => {setCreateScreen("create_group")}}>
        <ListItemButton>
            <ListItemIcon>
                <Icon path={mdiAccountGroup} size={1} />
            </ListItemIcon>
            <ListItemText primary="Group" />
        </ListItemButton>
    </ListItem>
    
    <ListItem disablePadding onClick={() => {setCreateScreen("create_channel")}}>
        <ListItemButton>
            <ListItemIcon>
                <Icon path={mdiBullhorn} size={1} />
            </ListItemIcon>
            <ListItemText primary="Channel" />
        </ListItemButton>
    </ListItem>

    </List>)
}