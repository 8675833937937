import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextSplash = styled(Paper)(({ theme }) => ({
    backgroundColor: 'rgba(52, 52, 52, 0.6)',
    color: 'white',
    ...theme.typography.body2,
    textAlign: 'center',
    border: '0px',
    maxWidth: 300,
    minWidth: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 15,
    wordWrap: 'break-word',
    display: 'table',
}));

export const TextSplashCentered = styled(TextSplash)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)"
}));
