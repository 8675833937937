import * as React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import Cookies from 'js-cookie';

export const ThemeContent = (props) => {

    const [darkTheme, setDarkTheme] = React.useState(false)

    if (Cookies.get("dark_theme") === 'true' && darkTheme === false) {
        setDarkTheme(true)
    }


    return (<FormGroup>
        <FormControlLabel control={
            <Switch checked={darkTheme} onClick={() => {
                if (darkTheme === true) {
                    Cookies.set("dark_theme", false);
                } else {
                    Cookies.set("dark_theme", true);
                }
                window.location.reload();
            }} />
        } label="Dark mode" labelPlacement='start'/>
    </FormGroup>)
}