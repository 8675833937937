import * as React from 'react';
import { stringToColor, openChat } from '../../Functions/functions.js';
import {
    Box,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@mui/material';
import { useTheme } from '@emotion/react';

export const ChatObject = (props) => {
    const { ID, name, avatarURL, constants, webSocketWorkers, isSelectedChat, lastMessage, position, chatsLength } = props;
    const theme = useTheme();
    return (
    <Box>
        <ListItem
            onClick={(e) => {
                    if (e.altKey) {
                        window.open(document.URL+'?windowchat='+ID, '', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                    } else {
                        openChat(ID, name, avatarURL, constants, webSocketWorkers);
                    }
                }
            }
            sx={{
                padding: 1,
                marginTop: position===0? 0: 0.5,
                marginBottom: position===chatsLength-1? 0.5: 0,
                borderRadius: 5,
                backgroundColor: theme.palette.mode === "dark"?       isSelectedChat? "#313131": "#404040":   isSelectedChat? "#d6d6d6": "#e9e9e9",
                "&:hover":{
                    backgroundColor: theme.palette.mode === "dark"?   isSelectedChat? "#313131": "#505050":   isSelectedChat? "#d6d6d6": "#f2f2f2"
                },
                "&:active":{
                    backgroundColor: theme.palette.mode === "dark"? "#313131": "#e0e0e0"
                }
            }}>
        <ListItemAvatar>
            <Avatar alt={name}  sx={{ width: 45, height: 45, backgroundColor: stringToColor(name)}} src={avatarURL}>{name.split(' ')[0][0] + name.split(' ')[1][0]}</Avatar>
        </ListItemAvatar>
        <ListItemText sx={{userSelect: 'none', overflow: "hidden", whiteSpace: "nowrap"}} primary={name} secondary={lastMessage}/>
        </ListItem>
    </Box>)
}