import * as React from 'react';

import {
  Typography,
  TextField,
  List,
  IconButton,
  Grid,
} from '@mui/material';

import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight, mdiCog, mdiPlus } from '@mdi/js';

import { ChatsList } from './ChatsList.jsx';



export const ChatsPanel = (props) => {
    const { setIsCreateModalOpened, setIsSettingsModalOpened, userMeInfo, openedChatID, constants, webSocketWorkers, chatsList, isDesktop } = props;

    const [searchText, setSearchText] = React.useState([""]);
    const [compactMode, setCompactMode] = React.useState(false);

    let chatsListElement = (
      <ChatsList
        searchText={searchText}
        chatsList={chatsList}
        userMeInfo={userMeInfo}
        openedChatID={openedChatID}
        constants={constants}
        webSocketWorkers={webSocketWorkers}
      />
    );
    if (!compactMode || !isDesktop) {
      return (
        <Grid item sx={{width: isDesktop? 390: '100%', height: '100dvh', display: 'flex', flexDirection: 'column'}}>
          
          <Grid sx={{width: '100%', display: 'flex', direction: 'row', padding: 1, paddingBottom: 0.25}}>
            <Typography variant="h3" width={1} sx={{userSelect: 'none', margin:'0 0 0 2pt'}} textAlign={'left'}>Chats</Typography>

            <Grid sx={{display: 'flex', direction: 'column',height: 40, marginTop: 1}}>

              {isDesktop?
                <IconButton sx={{marginRight: 1}} onClick={() => {setCompactMode(true)}}>
                  <Icon path={mdiChevronLeft} size={1}/>
                </IconButton>
              : ""}
              
              <IconButton sx={{marginRight: 1}} onClick={() => {setIsCreateModalOpened(true)}}>
                <Icon path={mdiPlus} size={1} />
              </IconButton>

              <IconButton sx={{marginRight: 1}} onClick={() => {setIsSettingsModalOpened(true)}}>
                <Icon path={mdiCog} size={1}/>
              </IconButton>
            </Grid>
          </Grid>

          <Grid sx={{width: '100%', padding: 1, paddingTop: 0.25}}>
            <TextField
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              sx={{width:'100%'}}
              label={'Search'}
            />
          </Grid>

          <List sx={{ width: '100%', overflow: 'auto', flexGrow: '1', padding: '0 6px 0 6px'}}>
            {chatsListElement}
          </List>
        </Grid>
      );
    } else {
      return (
        <Grid item sx={{width: 73, height: '100dvh', display: 'flex', flexDirection: 'column'}}>

          <List sx={{ width: '100%', overflow: 'auto', flexGrow: '1', padding: '0 6px 0 6px'}}>
            <IconButton sx={{margin: 1.3}} onClick={() => {setCompactMode(false)}}>
                <Icon path={mdiChevronRight} size={1} />
              </IconButton>
            {chatsListElement}
          </List>
        </Grid>
      );
    }
}