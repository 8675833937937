import * as React from 'react';
import { MessageFrameIncoming } from './Style';
import { stringToColor } from '../../../Functions/functions.js';
import { Avatar } from '@mui/material';
import { TextSplash } from "../../Misc/TextSplash";

export const IncomingUnsupportedMessage = (props) => {
    const { displayName, showName, avatarURI, showAvatar, timeText, isOneSource } = props

    let avatarBackgroundText = displayName;
    if (displayName.includes(" ")) {
        avatarBackgroundText = displayName.split(' ')[0][0] + displayName.split(' ')[1][0]
    }

    let nicknameElement = null;
    if (typeof(displayName) === 'string' && !isOneSource && showName) {
        nicknameElement = (
            <p style={{color:"gray", margin: 0, "textAlign":"left"}}>
                {displayName}
            </p>
        );
    }

    let avatarElement = null;
    if (!isOneSource) {
        if (showAvatar) {
            avatarElement = (
                <Avatar
                    alt={displayName}
                    sx={{ width: 45, height: 45, backgroundColor: stringToColor(displayName)}}
                    src={avatarURI}
                    style={{marginTop: "auto", marginBottom: -5}}>
                        {avatarBackgroundText}
                </Avatar>
            );
        } else {
            avatarElement = (<div style={{width:40}}></div>); // Empty space
        }
    }

    return (
        <div style={{display: 'flex', margin: 10}}>
            {avatarElement}
            <MessageFrameIncoming>
                {nicknameElement}
                <TextSplash>
                    Oh no. Unsuported message type. Is client updated?
                </TextSplash>
                <p style={{textAlign:'right',color:'gray', margin: 0}}>{timeText}</p>
            </MessageFrameIncoming>
        </div>
    );
}