import * as React from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import EmojiPicker from 'emoji-picker-react';
import { Box } from '@mui/material';

export default function EmojiPopup(props) {
  const { anchor, constants } = props;
  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  return (
      <BasePopup id={id} open={open} anchor={anchor}>
        <Box  sx={{boxShadow: '0px 0px 7px 3px rgba(0,0,0,0.43)', borderRadius: 3, marginBottom: '15px', marginLeft: '10px'}}>
            <EmojiPicker onEmojiClick={(emojiData, event) => {constants.setMessageText((prevInput) => prevInput + emojiData.emoji)}} customEmojis={[
                {
                names: ['Alice', 'alice in wonderland'],
                imgUrl:
                    'https://cdn.jsdelivr.net/gh/ealush/emoji-picker-react@custom_emojis_assets/alice.png',
                id: 'alice'
                },
                {
                names: ['Dog'],
                imgUrl:
                    'https://cdn.jsdelivr.net/gh/ealush/emoji-picker-react@custom_emojis_assets/dog.png',
                id: 'dog'
                },
                {
                names: ['Hat'],
                imgUrl:
                    'https://cdn.jsdelivr.net/gh/ealush/emoji-picker-react@custom_emojis_assets/hat.png',
                id: 'hat'
                },
                {
                names: ['Vest'],
                imgUrl:
                    'https://cdn.jsdelivr.net/gh/ealush/emoji-picker-react@custom_emojis_assets/vest.png',
                id: 'vest'
                }
            ]}/>
        </Box>
      </BasePopup>
  );
}