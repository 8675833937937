import { IncomingTextMessage } from '../../Components/Messages/Incoming/Text.jsx'
import { IncomingUnsupportedMessage } from '../../Components/Messages/Incoming/Unsupported.jsx'

import { OutgoingTextMessage } from '../../Components/Messages/Outgoing/Text.jsx'
import { OutgoingUnsupportedMessage } from '../../Components/Messages/Outgoing/Unsupported.jsx'

import { TextSplash } from '../../Components/Misc/TextSplash.jsx';

export const dateRender = (timestampObject) => {
    let timestampObjectDate = timestampObject.getDate();

    let timestampObjectDay = timestampObject.getDay();
    var weekDays = ['Sunday', 'Monday','Thuesday','Wednesday', 'Thursday', 'Friday', 'Saturday']
    let timestampObjectDayFull = weekDays[timestampObjectDay];
    
    let timestampObjectMonth = timestampObject.getMonth();
    var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var timestampObjectMonthFull = months[timestampObjectMonth];
    
    let timestampObjectFullYear = timestampObject.getFullYear();
    
    return (
        <TextSplash>
        {timestampObjectDayFull + ", " + timestampObjectDate + " " + timestampObjectMonthFull +  " " + timestampObjectFullYear}
        </TextSplash>
    );
}

export const outgoingMessage = (chatMessage, timestampObjectConverted) => {
    if (chatMessage.type === "text") {
        return (
            <OutgoingTextMessage
                messageText={chatMessage.message}
                timeText={timestampObjectConverted}
            />
        )
    } else {
        return (
            <OutgoingUnsupportedMessage
                timeText={timestampObjectConverted}
            />
        )
    }
}

export const incomingMessage = (previousMessage, chatMessage, nextMessage, timestampObjectConverted, isOneSource) => {
    let userNickname = chatMessage.from_user.first_name + " " + chatMessage.from_user.last_name;
    
    let avatarURI = undefined;
    let showAvatar = false;
    if (!isOneSource) {
        
        if (!nextMessage || chatMessage.from_user.user_id !== nextMessage.from_user.user_id) {
            showAvatar = true;
            avatarURI = chatMessage.from_user.avatar;
        }
    }

    let showName = false;
    if (!previousMessage || chatMessage.from_user.first_name+chatMessage.from_user.last_name !== previousMessage.from_user.first_name+previousMessage.from_user.last_name) {
        showName = true;
    }


    if (chatMessage.type === "text") {
        return (
            <IncomingTextMessage
                displayName={userNickname}
                showName={showName}
                messageText={chatMessage["message"]}
                timeText={timestampObjectConverted}
                avatarURI={avatarURI}
                showAvatar={showAvatar}
                isOneSource={isOneSource}
            />
        );
    } else {
        return (
            <IncomingUnsupportedMessage
                displayName={userNickname}
                showName={showName}
                avatarURI={avatarURI}
                showAvatar={showAvatar}
                timeText={timestampObjectConverted}
                isOneSource={isOneSource}
            />
        );
    }


    
}