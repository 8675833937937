
export const onNewDataFromServer = (constants, showError, Cookies) => {
    if (constants.lastJsonMessage) {
        console.log("DEBUG: WS REPLY:", constants.lastJsonMessage)
        const type = constants.lastJsonMessage.type;
        if (type === "reply") {
            let worker = constants.webSocketWorkers[constants.lastJsonMessage.query_id];
            // constants.setWebSocketWorkers({...constants.webSocketWorkers}) 
            // TODO: Removing workers which don't need anymore, but i don't know how to delete keys from JSON constant

            if (worker === "signin") {
                if (constants.lastJsonMessage.include.error === 0) {
                    Cookies.set("token", constants.lastJsonMessage.include.token);
                    window.location.reload(false);
                } else {
                    showError("Signin error " + constants.lastJsonMessage.include.error + ": "+constants.lastJsonMessage.include.text, constants);
                }
            } else if (worker === "signup") {
                if (constants.lastJsonMessage.include.error === 0) {
                    Cookies.set("token", constants.lastJsonMessage.include.token);
                    window.location.reload(false);
                } else {
                    showError("Signup error " + constants.lastJsonMessage.include.error + ": "+constants.lastJsonMessage.include.text, constants);
                }
            } else {
                showError("Unexcepted error: No actions for \""+worker+"\" worker in server reply ", constants);
            }
        } 
        else if (type === "update") {
            // There nothing to update
        }
    } 
}