import {
    Box,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@mui/material';
import Cookies from 'js-cookie';
import { ReadyState } from "react-use-websocket"
import { dateRender, incomingMessage, outgoingMessage } from './Renders/messagesRender.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { TextSplash, TextSplashCentered } from '../Components/Misc/TextSplash.jsx';

export function makeID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}



export function openChat(chatID, chatName, avatarUrl, constants, webSocketWorkers) {
    constants.setOpenedChatID(chatID);
    constants.setOpenedChatName(chatName);
    constants.setOpenedChatAvatarURL(avatarUrl);
    requestMessagesList(constants, webSocketWorkers, chatID)
}



export const RenderUsersList = (constants, webSocketWorkers) => {
    let ElementUsersList = [];
    let searchText = ""
    if (typeof(constants.searchText) == typeof("")) {
        searchText = constants.searchText
    }

    if (constants.chatsList.length === 0) {
        ElementUsersList.push(
            <TextSplashCentered>Loading...</TextSplashCentered>
        );
        return ElementUsersList;
    } else {
        for (let i = 0; i < constants.chatsList.length; i++) {
            let userInfo = constants.chatsList[i];

            let showResult = true;
            let resultID = null;
            let resultName = "Unknown User";
            let resultAvatarURL = "deleted_account.jpg";
            let resultLastMessage = "No messages";

            
            if (userInfo.chat_type === "private") {

                resultID = userInfo.user_id;

                if (userInfo.user_id === constants.userMeInfo.user_id) {
                    resultName = "Private space"
                    resultAvatarURL = "bookmark.jpg"
                } else {
                    resultName = userInfo["first_name"]+" "+userInfo["last_name"];
                    resultAvatarURL = userInfo.avatar;
                }

                if (userInfo.last_message !== null) {
                    if (userInfo.last_message.from_user.user_id === constants.userMeInfo.user_id) {
                        resultLastMessage = "You: "
                    } else {
                        resultLastMessage = ""
                    }

                    if (userInfo.last_message.type === "text") {
                        resultLastMessage += userInfo.last_message.message
                    } else {
                        resultLastMessage += "Unable to render message"
                    }
                }
            } else if (userInfo.chat_type === "chat") {
                resultID = userInfo.chat_id

                resultName = userInfo["name"];
                resultAvatarURL = null;

                if (userInfo.last_message !== null) {
                    if (userInfo.last_message.from_user.user_id === constants.userMeInfo.user_id) {
                        resultLastMessage = "You: "
                    } else {
                        resultLastMessage = userInfo.last_message.from_user.first_name.substring(0, 1) + ". " + userInfo.last_message.from_user.last_name + ": "
                    }

                    if (userInfo.last_message.type === "text") {
                        resultLastMessage += userInfo.last_message.message
                    } else {
                        resultLastMessage += "Unable to render message"
                    }
                }
            }
            if (searchText.trim() !== "") {
                if ((resultName.toLowerCase()).includes(searchText.toLowerCase())) {
                    showResult = true
                } else {
                    showResult = false
                }
            }
            
            var isSelectedChat = false
            if (typeof(constants.openedChatID) !== typeof([])) {
                if (resultID === constants.openedChatID) {
                    isSelectedChat = true
                }
            }

            if (showResult) {
                ElementUsersList.push(
                <Box>
                    <ListItem
                        onClick={() => openChat(resultID, resultName, resultAvatarURL, constants, webSocketWorkers)}
                        sx={{
                            padding: 1,
                            marginTop: i===0? 0: 0.5,
                            marginBottom: i===constants.chatsList.length-1? 0.5: 0,
                            borderRadius: 5,
                            backgroundColor: constants.darkTheme?    isSelectedChat? "#313131": "#404040":     isSelectedChat? "#d6d6d6": "#e9e9e9",
                            "&:hover":{
                                backgroundColor: constants.darkTheme?   isSelectedChat? "#313131" : '#505050':   isSelectedChat? "#d6d6d6" : '#f2f2f2'
                            },
                            "&:active":{
                                backgroundColor: constants.darkTheme? "#313131": "#e0e0e0"
                            }
                        }}>
                    <ListItemAvatar>
                        <Avatar alt={userInfo[resultName]}  sx={{ width: 45, height: 45, backgroundColor: stringToColor(resultName)}} src={resultAvatarURL}>{resultName.split(' ')[0][0] + resultName.split(' ')[1][0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{userSelect: 'none', overflow: "hidden", whiteSpace: "nowrap"}} primary={resultName} secondary={resultLastMessage}/>
                    </ListItem>
                </Box>
                );
            }
        }
        if (searchText.trim() !== "") {
            return [
                <TextSplash>Global search:</TextSplash>,
                <Box sx={{backgroundColor: '#dag', borderRadius: 10}}>
                    <ListItem
                        onClick={() => {}}
                        sx={{
                            padding: 1,
                            marginTop: 0.5,
                            borderRadius: 5,
                            backgroundColor: constants.darkTheme? "#404040": "#e9e9e9",
                            "&:hover":{
                                backgroundColor: constants.darkTheme? '#505050': '#f2f2f2'
                            },
                            "&:active":{
                                backgroundColor: constants.darkTheme? "#313131": "#e0e0e0"
                            }
                        }}>
                    <ListItemAvatar>
                        <Avatar alt={""}  sx={{ width: 45, height: 45, backgroundColor: stringToColor("-")}} src={null}>{"-"}</Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{userSelect: 'none', overflow: "hidden", whiteSpace: "nowrap"}} primary={"Placeholder"} secondary={"No sense, just prepared for future"}/>
                    </ListItem>
                </Box>,
                <TextSplash>Local search:</TextSplash>,
                ElementUsersList
            ]
        } else {
            return ElementUsersList;
        }
    }
    
};



export const renderChatMessages = (constants, customChatHistory=null) => {
    let ElementMessagesList = [];
    let previousMessage = null;

    let usingChatHistory = constants.chatHistory;
    if (customChatHistory !== null) {
        usingChatHistory = customChatHistory;
    };


    if (usingChatHistory.length > 0) {
        for (let i = 0; i < usingChatHistory.length; i++) {
            let chatMessage = usingChatHistory[i];

            let timestampObject = new Date(chatMessage.timestamp * 1000);
            let timestampObjectMinutes = "0" + timestampObject.getMinutes();
            let timestampObjectHours = timestampObject.getHours();
            let timestampObjectConverted = timestampObjectHours + ":" + timestampObjectMinutes.slice(-2);

            let nextMessage = null;
            if (i+1 < usingChatHistory.length) {
                nextMessage = usingChatHistory[i+1]
            }

            if (chatMessage.from_user.deleted === true ) {
                chatMessage.from_user.first_name = "Deleted"
                chatMessage.from_user.last_name = "account" //+ " (id" + chatMessage.from_user.user_id + ")" 
                chatMessage.from_user.avatar = "deleted_account.jpg"
            }



            
            if (!previousMessage || timestampObject.toDateString() !== new Date(previousMessage.timestamp * 1000).toDateString()) {
                ElementMessagesList.push(
                    dateRender(timestampObject)
                );
            }
            
            if (chatMessage.from_user.user_id === constants.userMeInfo.user_id) {
                ElementMessagesList.push(
                    outgoingMessage(chatMessage, timestampObjectConverted)
                );
            } else {
                ElementMessagesList.push(
                    incomingMessage(previousMessage, chatMessage, nextMessage, timestampObjectConverted, false) // TODO: Make normal isOneSource detection (based on chat type)
                );
            };

            previousMessage = chatMessage;
        };
    } else {
        ElementMessagesList.push(
            <TextSplashCentered>
                There's no messages. Send one and you will see it
            </TextSplashCentered>
        )
        console.log("W: Chat messages not rendered because chatHistory is empty")
    };
    ElementMessagesList.push(
        <div id="message-history-end" style={{
            display: 'table',
        }}/>
    )

    constants.setChatHistoryRendered(ElementMessagesList);
    window.setTimeout ( () => {
        scrollToBottomMessageHistory(constants);
    }, 10); // 10 miliseconds later, because changing constants and re-rendering page takes longer that scrolling to bottom
};

export const changeMeInfo = (constants, webSocketWorkers, first_name, last_name, email) => {
    if (first_name !== constants.userMeInfo.first_name ||
        last_name !== constants.userMeInfo.last_name ||
        email !== constants.userMeInfo.email) {
        
        if (constants.readyState === ReadyState.OPEN) {
            let queryID = makeID(10);
            constants.sendJsonMessage({"method":"user.set.info", "first_name":first_name, "last_name":last_name, "email":email, "token":Cookies.get("token"), "query_id":queryID});
            webSocketWorkers[queryID] = 'updatechats';
        }
    }
};

export const requestChatsList = (constants, webSocketWorkers) => {
    if (constants.readyState === ReadyState.OPEN) {
        let queryID = makeID(10);
        constants.sendJsonMessage({"method":"user.get.chats", "token":Cookies.get("token"), "query_id":queryID});
        webSocketWorkers[queryID] = 'updatechats';
    }
};



export const requestMessagesList = (constants, webSocketWorkers, chatid) => {
    if (constants.readyState === ReadyState.OPEN) {
        let queryID = makeID(10);
        constants.sendJsonMessage({"method":"get.message.history", "chat_id":chatid, "token":Cookies.get("token"), "query_id":queryID});
        webSocketWorkers[queryID] = 'reloadhistory';
    }
};



export const requestMe = (constants, webSocketWorkers) => {
    if (constants.readyState === ReadyState.OPEN) {
        let queryID = makeID(10);
        constants.sendJsonMessage({"method":"user.get.me", "token":Cookies.get("token"),"query_id":queryID});
        webSocketWorkers[queryID] = 'getme';
    }
};



export const replyWorker = (constants, webSocketWorkers, setChatsList, windowChat) => {
    if (constants.lastJsonMessage.query_id !== null) {

        let worker = webSocketWorkers[constants.lastJsonMessage.query_id];
        delete webSocketWorkers[constants.lastJsonMessage.query_id]

        console.log("DBG", constants.lastJsonMessage, constants.lastJsonMessage.query_id, worker, webSocketWorkers);

        if (worker === "reloadhistory") {
            constants.setChatHistory(constants.lastJsonMessage.include.history);
            renderChatMessages(constants, constants.lastJsonMessage.include.history);
        } else if (worker === "getme") {
            const root = ReactDOM.createRoot(document.getElementById('root'));
            if (constants.lastJsonMessage.include.error === 0) {
                constants.setUserMeInfo(constants.lastJsonMessage.include.account);
            } else if (constants.lastJsonMessage.include.error === -1) { // Websocket server error
                root.render(
                    <p>Opps... WebSocket server error. Try again later</p>
                );
            } else { // Deleted account?
                logOut();
            };
        } else if (worker === "updatechats") {
            setChatsList(constants.lastJsonMessage.include.chats);
            
            // For external window
            if (windowChat !== null) {
                let isChatFounded = false;
                for (let i = 0; i <= constants.lastJsonMessage.include.chats.length; i++) {
                    let chat = constants.lastJsonMessage.include.chats[i];
                    if (chat.chat_type === "private") {
                        if (windowChat === chat.user_id.toString()) {
                            let resultName = chat.first_name+" "+chat.last_name;
                            let resultAvatarURL = chat.avatar;
                            if (chat.user_id === constants.userMeInfo.user_id) {
                                resultName = "Private space"
                                resultAvatarURL = "bookmark.jpg"
                            }
                            isChatFounded = true;
                            openChat(chat.user_id, resultName, resultAvatarURL, constants, webSocketWorkers);
                            break;
                        }
                    } else if (chat.chat_type === "chat") {
                        if (windowChat === chat.chat_id.toString()) {
                            let resultName = chat.name;
                            let resultAvatarURL = chat.avatar;
                            isChatFounded = true;
                            openChat(windowChat, resultName, resultAvatarURL, constants, webSocketWorkers);
                            break;
                        }
                    }
                }
                if (!isChatFounded) {
                    window.close();
                }
            }
            
        };
    };
};

export const logOut = () => {
    Cookies.remove("token");
    window.location.reload();
}

export const closeChat = (constants) => {
    constants.setOpenedChatID([])
}

export const scrollToBottomMessageHistory = (constants) => {
    // messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })

    const element = document.getElementById('message-history-end');
    element?.scrollIntoView({
        behavior: 'smooth'
    }); 
}


export const updateMessageHistory = (constants, webSocketWorkers) => {
    if (constants.lastJsonMessage.include.chat_id === constants.openedChatID) {
        let updatedChatHistory = [...constants.chatHistory, constants.lastJsonMessage.include]
        constants.setChatHistory(updatedChatHistory);
        renderChatMessages(constants,updatedChatHistory);
    }
    requestChatsList(constants, webSocketWorkers)
}



export const updateChatsList = (constants) => {
    let updatedChatsList = [...constants.chatsList, constants.lastJsonMessage.include.chat]
    constants.setChatsList(updatedChatsList);
}



export const handleSendMessage = (constants, webSocketWorkers) => {
    if (constants.messageText.trim() !== "") {
        let messageText = constants.messageText.trim();
        constants.setMessageText("");
        constants.sendJsonMessage({"method":"send.message", "token":Cookies.get("token"), "chat_id":constants.openedChatID, "type":"text", "message":messageText});
        let updatedChatHistory = [...constants.chatHistory, {"message": messageText, "type":"text","from_user": constants.userMeInfo, "timestamp":Date.now() / 1000}]
        constants.setChatHistory(updatedChatHistory);
        renderChatMessages(constants, updatedChatHistory);
        requestChatsList(constants, webSocketWorkers);
    }
}

export function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }