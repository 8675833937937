import * as React from 'react';
import { mdiAccountEdit, mdiBrightness6, mdiLogout } from '@mdi/js';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Icon from '@mdi/react';

export const HomeContent = (props) => {
    const { setSettingsScreen } = props

    return (<List sx={{width: 300}}>
    
        <ListItem disablePadding onClick={() => {setSettingsScreen("account")}}>
            <ListItemButton>
                <ListItemIcon>
                    <Icon path={mdiAccountEdit} size={1} />
                </ListItemIcon>
                <ListItemText primary="Account" />
            </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => {setSettingsScreen("theme")}}>
            <ListItemButton>
                <ListItemIcon>
                    <Icon path={mdiBrightness6} size={1} />
                </ListItemIcon>
                <ListItemText primary="Theme" />
            </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => {setSettingsScreen("logout")}}>
            <ListItemButton>
                <ListItemIcon>
                    <Icon color="red" path={mdiLogout} size={1} />
                </ListItemIcon>
                <ListItemText primary="Log out" sx={{color: 'red'}} />
            </ListItemButton>
        </ListItem>

    </List>)
}