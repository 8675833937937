import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SidePart2 = styled(Paper)(({ theme }) => ({
    backgroundImage: theme.palette.mode === "dark"? "url(\"chat_background_dark.png\")": "url(\"chat_background.png\")",
    backgroundSize: "150px",
    backdropFilter: "opacity(20%)",
    ...theme.typography.body2,
    flex: 1,
    display: 'flex',
    flexDirection:'column',
    height: '100dvh',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
  
export const WidePanel = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '0px',
    borderTop: '1px',
    borderStyle: 'solid',
    borderColor: '#D6B3EF'
}));
  
export const WidePanel1 = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '0px',
    borderBottom: '1px',
    borderStyle: 'solid',
    borderColor: '#D6B3EF',
    boxShadow: 'none',
    display: 'flex'
}));
  
  
export const MessagesFrame = styled(Paper)(() => ({
    flexGrow: 1,
    width: '100%',
    background: 'none',
    overflow: 'auto'
}));