import * as React from 'react';
import { HomeContent } from './Home';



import BootstrapDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { mdiClose, mdiArrowLeft } from '@mdi/js';
import { Box, IconButton, Typography } from '@mui/material';
import Icon from '@mdi/react';


export const CreateForm = (props) => {
    const { isOpened , setIsOpened } = props;

    const [createScreen, setCreateScreen] = React.useState("home");
    

    const handleClose = () => {
        setCreateScreen("home");
        setIsOpened(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (isOpened) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isOpened]);

    let windowTitle = "Create";
    let showBackButton = true;
    let backButtonScreen = "home";
    let windowBottomButtons = (<div/>);    
    let currentScreenContent = (<div/>)

    
    if (createScreen === "create_group") {
        windowTitle = "Create group"
        showBackButton = true;
        backButtonScreen = "home";
        currentScreenContent = (
            <Typography width={1} sx={{userSelect: 'none'}} textAlign={'left'}>Not today :(</Typography>
        )
    } else if (createScreen === "create_channel") {
        windowTitle = "Create channel"
        showBackButton = true;
        backButtonScreen = "home";
        currentScreenContent = (
            <Typography width={1} sx={{userSelect: 'none'}} textAlign={'left'}>Not today :(</Typography>
        )
    } else {
        windowTitle = "Settings";
        showBackButton = false;    
        currentScreenContent = (
            <HomeContent setCreateScreen={setCreateScreen}/>
        )
    }




    return (
        <BootstrapDialog
            open={isOpened}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <Box sx={{display: 'flex', flexDirection: 'row', padding: 1}}>

                {showBackButton? 
                    <IconButton onClick={(e) => {setCreateScreen(backButtonScreen)}}>
                        <Icon path={mdiArrowLeft} size={1}/>
                    </IconButton>
                : null}

                <DialogTitle sx={{p: 0.5, m: 0, flexGrow: 1}}  id="scroll-dialog-title">{windowTitle}</DialogTitle>

                <IconButton aria-label="close" onClick={handleClose}>
                    <Icon path={mdiClose} size={1}/>
                </IconButton>
            </Box>

            <DialogContent dividers>
                {currentScreenContent}
            </DialogContent>
            {windowBottomButtons}

        </BootstrapDialog>
    );
}