
import { useEffect } from 'react';
import {
  requestMe,
  requestChatsList,
  replyWorker,
  updateMessageHistory,
  updateChatsList,
} from './Functions/functions.js';
import CreateConstants from './additions/constants.js';

import {
  Box,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Grid,
} from '@mui/material';

import Icon from '@mdi/react';
import { mdiSendVariant, mdiEmoticon, mdiPaperclip, mdiChevronLeft } from '@mdi/js';

import {
  handleSendMessage,
  stringToColor
} from './Functions/functions.js';
import {
SidePart2,
WidePanel,
WidePanel1,
MessagesFrame
} from './additions/custom_elements.js';
import { SettingsForm } from './Components/Forms/Settings/Main.jsx';
import React from 'react';
import { CreateForm } from './Components/Forms/Create/Main.jsx';
import { TextSplashCentered } from './Components/Misc/TextSplash.jsx';
import { ChatsPanel } from './Components/ChatsList/Main.jsx';
import EmojiPopup from './Components/Popups/EmojiPopups/Main.jsx';



let webSocketWorkers = {};



function App() {
  const constants = CreateConstants();
  const searchParams = new URLSearchParams(window.location.search);
  const windowChat = searchParams.get("windowchat");

  window.addEventListener('resize', () => {
    constants.setIsDesktop(window.innerWidth > 800)
  });
  var isChatOpened = typeof(constants.openedChatID) !== typeof([]);
  const [isSettingsModalOpened, setIsSettingsModalOpened] = React.useState();
  const [isCreateModalOpened, setIsCreateModalOpened] = React.useState();
  const [chatsList, setChatsList] = React.useState([]);
  const [anchor, setAnchor] = React.useState(null);

  const handleEmojiClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  
  useEffect(() => { // On page load
    if (typeof(constants.openedChatID) !== typeof([])) {
      if (constants.messageFrameRef) {
          constants.messageFrameRef.current.addEventListener('DOMNodeInserted', event => {
              const { currentTarget: target } = event;
              target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
          });
      }
    }
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => { // On connected status
    requestMe(constants, webSocketWorkers);
    requestChatsList(constants, webSocketWorkers);
    // eslint-disable-next-line
  }, [constants.readyState])

  useEffect(() => { // On new data
    if (constants.lastJsonMessage) {
      const type = constants.lastJsonMessage.type;
      if (type === "reply") {
          replyWorker(constants, webSocketWorkers, setChatsList, windowChat);
      } 
      else if (type === "update") {
          if (constants.lastJsonMessage.include.method === "new.message") {
              console.log(constants.lastJsonMessage.include)
              updateMessageHistory(constants, webSocketWorkers);
          } else if (constants.lastJsonMessage.include.method === "new.chat") {
              updateChatsList(constants);
          }
      }
    } 
    // eslint-disable-next-line
  }, [constants.lastJsonMessage]);

    let SidePart1Elements = (
      <ChatsPanel
        setIsCreateModalOpened={setIsCreateModalOpened}
        setIsSettingsModalOpened={setIsSettingsModalOpened}
        userMeInfo={constants.userMeInfo}
        openedChatID={constants.openedChatID}
        constants={constants}
        webSocketWorkers={webSocketWorkers}
        chatsList={chatsList}
        isDesktop={constants.isDesktop}
      />)

  if (!constants.isDesktop && isChatOpened) {
    SidePart1Elements = null;
  }

  let SidePart2Elements = null;
  if (constants.isDesktop) {
    SidePart2Elements = <SidePart2>
        <TextSplashCentered>Select chat from left column</TextSplashCentered>
      </SidePart2>
  }
  if (typeof(constants.openedChatID) !== typeof([])) {
    SidePart2Elements = 
    <SidePart2>
        <WidePanel1>
            {windowChat!==null? <Box width={"10pt"}></Box>: <IconButton sx={{margin: 1.5}} onClick={(e) => {constants.setOpenedChatID([])}}>
              <Icon path={mdiChevronLeft} size={1.5}/>
            </IconButton>}
          <ListItem onClick={(e) => {}} disablePadding>
            <ListItemAvatar>
              <Avatar alt={constants.openedChatName} sx={{ width: 45, height: 45, backgroundColor: stringToColor(constants.openedChatName)}} src={constants.openedChatAvatarURL}>{constants.openedChatName.split(' ')[0][0] + constants.openedChatName.split(' ')[1][0]}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={constants.openedChatName} secondary={"idk when online"} />
          </ListItem>
        </WidePanel1>

        <MessagesFrame id="messages_frame" ref={constants.messageFrameRef}>
          {constants.chatHistoryRendered}
        </MessagesFrame>

        <WidePanel>
            <Grid container>
              <Grid item>
              <IconButton
                  sx={{
                    backgroundColor: constants.DarkTheme? '#673787':'#b291c9',
                    color: 'white',
                    "&:hover": {backgroundColor: '#D6B3EF'},
                    margin: '15px',
                    marginLeft: '15px',
                    marginRight: '0px',
                    height: 55,
                    width: 55,
                    borderRadius: 1,
                  }}
                  variant="filled"
                  color="primary"
                ><Icon path={mdiPaperclip} size={1}/></IconButton>
              </Grid>
              <Grid item>
              <IconButton
                  sx={{
                    backgroundColor: constants.DarkTheme? '#673787':'#b291c9',
                    color: 'white',
                    "&:hover": {backgroundColor: '#D6B3EF'},
                    margin: '15px',
                    marginLeft: '15px',
                    marginRight: '0px',
                    height: 55,
                    width: 55,
                    borderRadius: 1,
                  }}
                  onClick={handleEmojiClick}
                  variant="filled"
                  color="primary"
                ><Icon path={mdiEmoticon} size={1}/></IconButton>
              </Grid>
              <Grid item xs>
                <TextField
                  onChange={(e) => {
                    constants.setMessageText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      console.log(constants.messageText.trim())
                      if (constants.messageText.trim() !== "") {
                        handleSendMessage(constants, webSocketWorkers);
                      }
                      e.preventDefault();
                    }
                  }}
                
                  sx={{width:'100%', margin: '15px', alignSelf: 'start'}}
                  label={'Message'}
                  value={constants.messageText}
                  maxRows={4}
                  multiline
                />
              </Grid>
              <Grid item>
              <IconButton
                  sx={{
                    backgroundColor: constants.DarkTheme? '#673787':'#b291c9',
                    color: 'white',
                    "&:hover": {backgroundColor: '#D6B3EF'},
                    margin: '15px',
                    marginLeft: '25px',
                    height: 55,
                    width: 55,
                    borderRadius: 1,
                  }}
                  onClick={(e) => handleSendMessage(constants, webSocketWorkers)}
                  variant="filled"
                  color="primary"
                ><Icon path={mdiSendVariant} size={1}/></IconButton>
              </Grid>
            </Grid>


          </WidePanel>
    </SidePart2>
  }


  if (windowChat != null) {
    return (SidePart2Elements)
  } else {
    return (
      <Box sx={{display: 'flex'}}>
        <SettingsForm isOpened={isSettingsModalOpened} setIsOpened={setIsSettingsModalOpened} meInfo={constants.userMeInfo}/>
        <CreateForm isOpened={isCreateModalOpened} setIsOpened={setIsCreateModalOpened} />
        <EmojiPopup anchor={anchor} constants={constants}/>
        {SidePart1Elements}
        {SidePart2Elements}
      </Box>
    );
  }
}


export default App;
