import * as React from 'react';
import { Avatar, Box, FormGroup, Button, TextField, Typography, DialogActions } from '@mui/material';
import { mdiContentSave } from '@mdi/js';
import { stringToColor } from '../../../Functions/functions';
import Icon from '@mdi/react';


export const AccountContent = (props) => {
    const { meInfo } = props;

    const [accountFirstName, setAccountFirstName] = React.useState(meInfo !== undefined? meInfo.first_name: "First name");
    const [accountLastName, setAccountLastName] = React.useState(meInfo !== undefined? meInfo.last_name: "Last name");
    const [accountEmail, setAccountEmail] = React.useState(meInfo !== undefined? meInfo.email: "Email");

    if (meInfo === undefined) {
        return (<FormGroup sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            <Typography width={1} sx={{userSelect: 'none'}} textAlign={'left'}>Failed to load account information</Typography>
        </FormGroup>)
    }

    console.log(accountFirstName, accountLastName)

    let shortName = "";
    if (accountFirstName !== "") {
        shortName += accountFirstName[0];
    }
    if (accountLastName !== "") {
        shortName += accountLastName[0];
    }
    


    return (<FormGroup sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
                <TextField
                    onChange={
                        (e) => {
                            setAccountFirstName(e.target.value);
                        }
                    }
                    value={accountFirstName}
                    sx={{ width: '100%' }
                    }
                    label={'First name'}
                />
                <TextField
                    onChange={
                        (e) => {
                            setAccountLastName(e.target.value);
                        }
                    }
                    value={accountLastName}
                    sx={{ width: '100%', marginTop: 1 }}
                    label={'Last name'}
                />
            </Box>
            <Box sx={{margin: 0.3}}>
                <Avatar sx={
                    {
                        width: 90,
                        height: 90,
                        backgroundColor: stringToColor(shortName)
                    }
                } src={""} >
                        {shortName}
                </Avatar>
                <Button variant="contained" size="small" sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: 0.4, display: 'block'}}>Change</Button>
            </Box>
        </Box>
        <Box>
            <TextField
                onChange={
                    (e) => {
                        setAccountEmail(e.target.value);
                    }
                }
                value={accountEmail}
                sx={{ width: '100%', marginTop: 1 }}
                label={'E-Mail'}
            />
        </Box>
    </FormGroup>)
}


export const AccountButtonContent = (props) => {
    const { meInfo } = props;

    if (meInfo === undefined) {
        return null;
    } else {
        return (
            <DialogActions>
                <Button startIcon={
                    <Icon path={mdiContentSave} size={1}/>
                } onClick={() => {}}>Save (Soon)</Button>
            </DialogActions>
        )
    }
}