import * as React from 'react';
import { Button, DialogActions, FormGroup, Typography } from '@mui/material';
import { logOut } from '../../../Functions/functions';
import Icon from '@mdi/react';
import { mdiLogout } from '@mdi/js';


export const LogoutContent = (props) => {
    return (<FormGroup sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <Typography width={1} sx={{userSelect: 'none'}} textAlign={'left'}>Are you sure?</Typography>
    </FormGroup>)
}


export const LogoutButtonContent = (props) => {
    return (
        <DialogActions>
            <Button startIcon={
                <Icon path={mdiLogout} size={1}/>
            } onClick={() => {logOut()}}>Log out</Button>
        </DialogActions>
    );
}