import React, { useState } from 'react';
import useWebSocket  from "react-use-websocket";
import Cookies from 'js-cookie';

const CreateConstants = () => {
    const [loginOrRegisterTab, setloginOrRegisterTab] = React.useState(0);

    const [SignInEmail, setSignInEmail] = useState("");
    const [SignInPassword, setSignInPassword] = useState("");
    const [SignUpEmail, setSignUpEmail] = useState("");
    const [SignUpPassword, setSignUpPassword] = useState("");
    const [SignUpFName, setSignUpFName] = useState("");
    const [SignUpLName, setSignUpLName] = useState("");

    const [stateSignInEmailInputField, setStateSignInEmailInputField] = useState({
    error: false,
    helperText: null
    });

    const [stateSignInPasswordInputField, setStateSignInPasswordInputField] = useState({
    error: false,
    helperText: null
    });

    const [stateSignUpEmailInputField, setStateSignUpEmailInputField] = useState({
    error: false,
    helperText: null
    });

    const [stateSignUpPasswordInputField, setStateSignUpPasswordInputField] = useState({
    error: false,
    helperText: null
    });

    const [stateSignUpFNameInputField, setStateSignUpFNameInputField] = useState({
    error: false,
    helperText: null
    });

    const [stateSignUpLNameInputField, setStateSignUpLNameInputField] = useState({
    error: false,
    helperText: null
    });

    const [stateErrorMsg, setStateErrorMsg] = useState({
    sx: {width: "calc(100% - 20px)", margin: '10px', backgroundColor: 'red', minHeight: '40px', borderRadius: '7px', display: 'none'}
    });

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    let darkThemeTemp = false
    if (Cookies.get("dark_theme") === "true") {
        darkThemeTemp = true
    }
    const [darkTheme, setDarkTheme] = useState(darkThemeTemp);

    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        process.env.REACT_APP_SERVER_URL,
        {
            shouldReconnect: () => true,
        },
    )


    const [webSocketWorkers, setWebSocketWorkers] = useState({});

    
    return {
        lastJsonMessage, readyState, sendJsonMessage, 
        webSocketWorkers, setWebSocketWorkers,
        loginOrRegisterTab, setloginOrRegisterTab,
        SignInEmail, setSignInEmail,
        SignInPassword, setSignInPassword,
        SignUpEmail, setSignUpEmail,
        SignUpPassword, setSignUpPassword,
        SignUpFName, setSignUpFName,
        SignUpLName, setSignUpLName,
        stateSignInEmailInputField, setStateSignInEmailInputField,
        stateSignInPasswordInputField, setStateSignInPasswordInputField,
        stateSignUpEmailInputField, setStateSignUpEmailInputField,
        stateSignUpPasswordInputField, setStateSignUpPasswordInputField,
        stateSignUpFNameInputField, setStateSignUpFNameInputField,
        stateSignUpLNameInputField, setStateSignUpLNameInputField,
        stateErrorMsg, setStateErrorMsg,
        isDesktop, setIsDesktop,
        darkTheme, setDarkTheme,
        
    };
}

export default CreateConstants;