import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MessageFrameOutgoing = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#008c67' : '#00b887',
    color: 'white',
    ...theme.typography.body2,
    textAlign: 'center',
    border: '0px',
    maxWidth: 300,
    minWidth: 10,
    padding: 10,
    paddingBottom: 3,
    margin: 10,
    marginRight: 20,
    marginLeft: 'auto',
    borderRadius: 15,
    borderBottomRightRadius: 0,
    wordWrap: 'break-word',
    display: 'table',
}));