import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';


export function makeID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}



export function signInClickHandle(constants) {
    if (constants.SignInEmail.trim() === "") {
        constants.setStateSignInEmailInputField({
            helperText: "This field is required",
            error: true
        });
    }

    if (constants.SignInPassword.trim() === "") {
        constants.setStateSignInPasswordInputField({
            helperText: "This field is required",
            error: true
        });
    }

    if (constants.SignInPassword.trim() !== "" && constants.SignInEmail.trim() !== "") {
        const queryID = makeID(10);
        constants.sendJsonMessage({"method":"user.sign.in", "email":constants.SignInEmail, "password":constants.SignInPassword, "query_id":queryID});
        constants.setWebSocketWorkers({...constants.webSocketWorkers, [queryID]: 'signin'});
    }
}




export function signUpClickHandle(constants) {
    if (constants.SignUpEmail.trim() === "") {
        constants.setStateSignUpEmailInputField({
            helperText: "This field is required",
            error: true
        });
    }

    if (constants.SignUpPassword.trim() === "") {
        constants.setStateSignUpPasswordInputField({
            helperText: "This field is required",
            error: true
        });
    }
    if (constants.SignUpFName.trim() === "") {
        constants.setStateSignUpFNameInputField({
            helperText: "This field is required",
            error: true
        });
    }

    if (constants.SignUpLName.trim() === "") {
        constants.setStateSignUpLNameInputField({
            helperText: "This field is required",
            error: true
        });
    }

    if (constants.SignUpEmail.trim() !== "" && constants.SignUpPassword.trim() !== "" && constants.SignUpFName.trim() !== "" && constants.SignUpLName.trim() !== "") {
        const queryID = makeID(10);
        constants.sendJsonMessage({"method":"user.sign.up", "email":constants.SignUpEmail, "password":constants.SignUpPassword, "first_name":constants.SignUpFName, "last_name":constants.SignUpLName, "query_id":queryID});
        constants.setWebSocketWorkers({...constants.webSocketWorkers, [queryID]: 'signup'});
    }
  }




export function hideError(constants) {
    constants.setStateErrorMsg({width: "calc(100% - 20px)", margin: '10px', backgroundColor: 'red', minHeight: '40px', borderRadius: '7px', display: 'none'});
}




export function showError(errorMessage, constants) {
    document.getElementById("error-text").innerHTML = errorMessage;
    constants.setStateErrorMsg({width: "calc(100% - 20px)", margin: '10px', backgroundColor: 'red', minHeight: '40px', borderRadius: '7px'});
}

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: "15px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export const handleChangeTab = (constants, newValue) => {
    constants.setloginOrRegisterTab(newValue);
};