import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/app/App';
import Login from './pages/login/Login';

import Cookies from 'js-cookie';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from './utils/themes';

const root = ReactDOM.createRoot(document.getElementById('root'));

let theme = "";
if (Cookies.get("dark_theme") === 'true') {
  theme = darkTheme;
} else {
  theme = lightTheme;
}

if (Cookies.get("token") !== undefined) {
  root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <App />
      </main>
    </ThemeProvider>
  );
} else {
  root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Login />
      </main>
    </ThemeProvider>
  )
}